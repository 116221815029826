body {
  padding-top: constant(safe-area-inset-top); /* iOS 11.2+ */
  padding-top: env(safe-area-inset-top); /* iOS 11.0-11.1 */
}

.pwa-mobile-warning-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.pwa-popup-content {
  text-align: center;
}

.pwa-calculator-container {
  margin: auto; /* Centers the div horizontally */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers content vertically */
  align-items: center; /* Centers content horizontally */
  width: 100%;
  height: 100%; 
}

.pwa-result-container {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 10px;
  padding-bottom: 0px;
  padding-top: 0px;
  border-width: 0;
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  flex-wrap: wrap;
}

.pwa-result-container.okay{
  background-color: rgb(221, 255, 221); /* Green background for greater clearance */
}

.pwa-result-container.bad{
  background-color: rgb(255, 217, 217); /* Red background for lesser clearance */
}

.pwa-result-container.disabled{
  background-color: #f0f0f0;
  pointer-events: none;
  opacity: 0.4;
}

.pwa-input-container {
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid #ccc;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  border-radius: 0;
  border-width: 0;
  background-color: transparent;
}

.pwa-input-container.disabled input, 
.pwa-input-container.disabled select {
  background-color: #f0f0f0;
  color: #999;
}

.pwa-section-container {
  margin-bottom: 20px;
}

.pwa-section-container.results {
  opacity: 0.5
}

.pwa-small-pill-selector {
  width: calc(33% - 10px);
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  background-color: #f1f1f1;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  color: #b1b1b1;
  border: 1px solid #B8B8B8;
  margin-top: 5px;
  margin-bottom: 5px;
  width: calc(100%);
}

.pwa-small-pill-selector.results { 
  pointer-events: none;
}

.pwa-tab {
  flex-grow: 1;
  background-color: #f1f1f1;
  color: #b1b1b1;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  position: relative;
  z-index: 1;
}

.pwa-tab.active {
  background-color: rgb(86, 86, 86);
  font-weight: 700;
  color: rgb(255, 255, 255);
}

.pwa-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color:rgb(86, 86, 86);
  z-index: 0;
  transition: transform 0.3s ease;
}

.pwa-slide-left {
  transform: translateX(0%);
}

.pwa-slide-right {
  transform: translateX(100%);
}

.pwa-side-by-side {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Pills vertically stacked */
  flex-direction: column;
  align-items: center;
}

#pwa-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; 
}

.pwa-inner-container {
  display: flex;
  width: 100%; 
  flex-wrap: wrap; /* Input boxes and results stacked */
  justify-content: space-around;
}

.pwa-item {
  flex: 1;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  flex-basis: calc(50% - 10px);
  box-sizing: border-box;
  padding-top: 5px;
  padding-bottom: 10px;
}

.pwa-item.results{
  opacity: 0.5;
}

.pwa-calculated-item {
  flex: 1;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  flex-basis: calc(50% - 10px);
  padding-top: 5px;
  padding-bottom: 15px;
  box-sizing: border-box;
}

.pwa-calculations {
  text-align: center;
  font-size: 1.25em;
  background-color: transparent;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.pwa-result-container .pwa-inner-container,
.pwa-result-container .pwa-inner-container {
  flex-wrap: wrap; /* Intermediate calculations and results items stacked */
}

.pwa-result-container .pwa-inner-container .pwa-calculated-item,
.pwa-result-container .pwa-inner-container .pwa-calculated-item {
  width: calc(50% - 10px); /* Two items per row */
}